import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  Dialog,
  IconButton,
} from "@mui/material";
import bg from "assets/images/background/popup.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import location from "assets/images/location.svg";
import { useState } from "react";
import axios from "axios";
import emailjs from "@emailjs/browser"; // ✅ EmailJS Import

export const departmentConstants = {
  OPTHOMOLOGY: "Ophthalmology",
  ORTHO: "Orthopaedics",
  PEDIATRICS: "Pediatrics (Pediatric surgery)",
  GYNO: "Obstetrics and Gynaecology",
  DERMA: "Dermatology",
  INTERNAL_MEDICINE: "InternalMedicine",
  DIBETOLOGY: "Diabetology",
  GEN_SURGERY: "GeneralSurgery",
  ENT: "ENT",
  CARDIO: "Cardiology",
  NEPHRO: "Nephrology",
  NEURO: "Neurology",
  PHYSIO: "Physiotherapy",
  GASTRO: "Gastroenterology",
  RADIO: "Radiology",
  CC: "CriticalCare",
};

export const locationList = ["Pursaiwalkam", "Sowcarpet"];

const MOBILE = "917695957120";

const BookAppointmentModel = ({ onClose, docName }) => {
  const [inputValues, setInputValues] = useState({});

  const URL = (message) => `https://wa.me/${MOBILE}?text=${encodeURI(message)}`;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const generateMessageString = () => {
    let values = Object.keys(inputValues);
    let message = docName ? "*Doctor*" + " : " + docName + "\n" : "";
    values.forEach(
      (value) =>
        (message =
          message +
          "\n" +
          "*" +
          value.toUpperCase() +
          "*" +
          " : " +
          inputValues[value])
    );

    const whatsAppUrl = URL(message);

    postData({
      patientName: inputValues.Patient,
      patientMobileNo: Number(inputValues.Phone),
      patientApptDate: new Date(inputValues.Date).toISOString(),
    });

    sendEmail(); // ✅ Email भेजने का फंक्शन कॉल

    window.open(whatsAppUrl);
  };

  const sendEmail = () => {
    const emailParams = {
      to_name: "Clinic Admin",
      patient_name: inputValues.Patient,
      patient_mobile: inputValues.Phone,
      appointment_date: inputValues.Date,
      department: inputValues.Department,
      location: inputValues.Location,
      message: `New appointment booked by ${inputValues.Patient} on ${inputValues.Date}. Contact: ${inputValues.Phone}`,
    };

    emailjs
    .send(
      "service_imd0gcq", // Replace with your EmailJS Service ID
      "template_yo1ecmd", // Replace with your EmailJS Template ID
      emailParams,
      "InuavJkVr0rf8xm0_" // Replace with your EmailJS Public Key
    )
      .then((response) => {
        console.log("Email Sent Successfully:", response.status, response.text);
        alert("Appointment details sent via Email!");
      })
      .catch((error) => {
        console.error("Email Sending Error:", error);
        alert("Failed to send email.");
      });
  };

  const postData = async (payload) => {
    try {
      const response = await axios.post(
        "http://210.18.187.203:8080/shc/onlineappointment",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Security-Policy": "upgrade-insecure-requests",
          },
        }
      );

      console.log("Response:", response.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0,0,0, 0.8)",
          },
        },
      }}
    >
      <Box
        borderRadius={"0.5rem"}
        boxShadow={"0 0 12px 0 rgba(153, 153, 153, 0.2)"}
        maxWidth={"43rem"}
        color="text.primary"
        bgcolor="common.white"
        display={"flex"}
      >
        <Box
          minWidth={"19rem"}
          color="#ccc"
          display={{ md: "flex", xs: "none" }}
          flexDirection={"column"}
          justifyContent={"end"}
          p={1}
          sx={{
            background: `url(${bg}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <Typography variant="body" fontWeight="bold" pl={4.5}>
            Amrit Hospital
          </Typography>
          <Box display="flex" alignItems="center" pt={1}>
            <img src={location} alt="location" />
            <Typography variant="subtitle2" ml={2}>
              362, Mint Street, Opposite to Jain Temple. Sowcarpet, Chennai - 600079, Tamil Nadu, India
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" pt={1}>
            <img src={location} alt="location" />
            <Typography variant="subtitle2" ml={2}>
              73/28, Dr Alagappa Road, Purasaiwakkam, Chennai - 600084, Tamil Nadu, India
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1}>
          <Box borderBottom={1} borderColor={"divider"} px={3} py={1.5} display="flex" justifyContent={"space-between"}>
            <Typography variant="body2" fontWeight={600}>
              Book an Appointment
            </Typography>
            <IconButton color="secondary" onClick={onClose}>
              <HighlightOffRoundedIcon />
            </IconButton>
          </Box>
          <Box px={3} pb={3} pt={2}>
            <Grid container spacing={2.5} pt={2}>
              <Grid item xs={12}>
                <TextField fullWidth placeholder="Patient Name" variant="outlined" size="small" onChange={onInputChange} inputProps={{ name: "Patient" }} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="outlined" placeholder="Select A Specialities" select onChange={onInputChange} inputProps={{ name: "Department" }} value={inputValues.Department || ""}>
                  <MenuItem value="" disabled>Select A Specialities</MenuItem>
                  {Object.values(departmentConstants)?.map((item, index) => (
                    <MenuItem value={item} key={index + 1}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="outlined" placeholder="Date" type="date" onChange={onInputChange} inputProps={{ name: "Date" }} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="outlined" placeholder="Mobile" onChange={onInputChange} inputProps={{ name: "Phone" }} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="outlined" placeholder="Select Location" select onChange={onInputChange} inputProps={{ name: "Location" }}> 
                  <MenuItem value="" disabled>Select Location</MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem value={item} key={index + 1}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box textAlign="left">
              <Button variant="contained" size="small" color="primary" sx={{ mt: 2.5, py: 1.25 }} onClick={generateMessageString}>
                Book an Appointment 
              </Button> 
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BookAppointmentModel;
